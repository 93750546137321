import { Dispatch, SetStateAction } from "react";

import { assets, buttonStyle, colors, colorsRGB, fontWeights } from "./constants";
import {
  SanityBlockContent,
  SanityCategory,
  SanityHexColors,
  SanityImage,
  SanityImageWithMeta,
  SanityLink,
  SanityMenu,
  SanityNoteComponent,
  SanityTypeForm,
} from "@graphql-types";

export type Dimensions = {
  width?: string;
  height?: string;
};

export type Asset = keyof typeof assets;
export type Color = keyof typeof colors;
export type ColorRGB = keyof typeof colorsRGB;
export type ButtonTheme = keyof typeof buttonStyle;
export type ButtonType = typeof buttonStyle;
export type FontWeights = keyof typeof fontWeights;
export type AssetType = typeof assets;

export type SetRegion = Dispatch<
  SetStateAction<{
    iban: string;
    langCode: string;
  }>
>;

export type AnimationDirection = {
  to: {
    transform?: string;
    opacity?: number;
  };
  from: {
    transform?: string;
    opacity?: number;
  };
};

export function isSanityImage(data: any): data is SanityImage {
  return data._type === "image";
}

export interface ScreenWidth {
  isTabletWidth: boolean;
  isMobileWidth: boolean;
  isLaptopWidth: boolean;
  isIPAD_PRO_BREAKPOINT: boolean;
}

export function isSanityMenu(data: any): data is SanityMenu {
  return data._type === "menu";
}

export function isSanityLink(data: any): data is SanityLink {
  return data._type === "link";
}

export function isSanityCategory(data: any): data is SanityCategory {
  return data._type === "category";
}

export function isSanityImageWithMeta(data: any): data is SanityImageWithMeta {
  return data._type === "imageWithMeta";
}

export function isSanityHexColors(data: any): data is SanityHexColors {
  return data._type === "hexColors";
}

export function isSanityNoteComponent(data: any): data is SanityNoteComponent {
  return data._type === "noteComponent";
}

export function isSanityBlockContent(data: any): data is SanityBlockContent {
  return data._type === "blockContent";
}

export function isSanityTypeForm(data: any): data is SanityTypeForm {
  return data._type === "typeForm";
}
