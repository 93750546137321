import React, { ReactNode } from "react";

import { materialTheme } from "@styles/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

interface Props {
  children: ReactNode;
}

const ProviderContainer = ({ children }: Props) => {
  return (
    <>
      <ThemeProvider theme={materialTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </>
  );
};

export default ProviderContainer;
