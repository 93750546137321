import React, { createElement } from "react";
import PortableText from "@sanity/block-content-to-react";

import { Maybe, SanityBlockContent } from "@graphql-types";
import { P } from "@util/standard";
import styled from "styled-components";
interface Props {
  data: Maybe<SanityBlockContent> | undefined;
  overrideColor?: string;
}

const StyledPortbaleText = styled(PortableText)<{ fontColor?: string }>`
  ${({ fontColor }) => fontColor && `h1,h3 {color: ${fontColor}}`}
`;

const BlocksContent = ({ data, overrideColor }: Props) => {
  if (data == null) {
    return null;
  }

  const { _rawBlockContent } = data;

  if (_rawBlockContent == null) {
    return null;
  }

  const block = (props: any) => {
    const { style = "normal" } = props.node;
    const tag = style;
    if (/^h\d/.test(tag)) {
      return createElement(tag, { style: { margin: "revert" } }, props.children);
    }
    return <P>{props.children}</P>;
  };

  return (
    <StyledPortbaleText
      blocks={_rawBlockContent}
      serializers={{
        types: { block },
      }}
      imageOptions={{ auto: "format", fit: "fill" }}
      projectId={process.env.SANITY_PROJECT_ID}
      dataset={process.env.GATSBY_NODE_ENV ?? "development"}
      fontColor={overrideColor}
    />
  );
};

export default BlocksContent;
