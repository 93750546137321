import React, { Dispatch } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Event } from "react-big-calendar";

import { colorsRGB, assets, fontSizes, fontWeights } from "@util/constants";
import { Container, P } from "@util/standard";
import { BlocksContent, Image } from "@global";

const CloseIcon = styled.img`
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
`;

const Title = styled(P)`
  font-size: ${fontSizes.h4.default}px;
  font-weight: ${fontWeights.bold};
`;

interface Props {
  modalVisible: boolean;
  modalEvent: Event | undefined;
  setModalVisible: Dispatch<React.SetStateAction<boolean>>;
}

const ModalBox = ({ modalVisible, modalEvent, setModalVisible }: Props) => {
  if (modalEvent == null) return null;
  const modalBaseStyle = {
    content: {
      top: "50%",
      left: "50%",
      width: "70%",
      height: "80%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
      borderRadius: "20px",
      overflow: "visible",
    },
    overlay: {
      backgroundColor: `${colorsRGB.black(0.6)}`,
      zIndex: 100,
    },
  };
  return (
    <Modal isOpen={modalVisible} style={modalBaseStyle}>
      <CloseIcon src={assets.closeBlack} onClick={() => setModalVisible(false)} />
      <Container justifyContent="center" flexDirection="column" alignItems="center">
        <Title>{modalEvent.title}</Title>
        <P>Start Date: {modalEvent.start?.toLocaleString()}</P>
        <P>End Date: {modalEvent.end?.toLocaleString()}</P>
      </Container>
      <Container flexDirection="row" width="100%" margin="auto" overflow="auto" height="75%">
        <Container width="50%" margin="auto">
          <Image data={modalEvent.image} objectFit="cover" />
        </Container>
        <Container width="50%" justifyContent="center">
          <BlocksContent data={modalEvent.description} />
        </Container>
      </Container>
    </Modal>
  );
};

export default ModalBox;
