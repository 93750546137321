import styled, { createGlobalStyle } from "styled-components";

import { colors, MOBILE_BREAKPOINT } from "@util/constants";
import { fontSizes, fontWeights, PRIMARY_COLOR } from "@util/constants";
import { createTheme } from "@mui/material/styles";

export const GlobalStyle = createGlobalStyle`
  
  body {
    margin: 0px;
    font-family: Inter, sans-serif;
    font-size: ${fontSizes.p.default}px;
    font-weight: ${fontWeights.normal};
    color: ${colors.tobeornottobe};
  }

  p {
    font-size: ${fontSizes.p.default}px;
    font-weight: ${fontWeights.normal};
    color: ${colors.tobeornottobe};
  }

  h1, .h1 {
    font-size: ${fontSizes.h1.default}px;
    font-weight: ${fontWeights.bold};
    color: ${PRIMARY_COLOR};
    line-height: 55px;

  }
  h2, .h2 {
    font-size: ${fontSizes.h2.default}px;
    font-weight: ${fontWeights.bold};
    margin: 10px 0;
    color: ${PRIMARY_COLOR};

  }
  h3, .h3 {
    font-size: ${fontSizes.h3.default}px;
    font-weight: ${fontWeights.bold};
    margin: 40px auto 10px auto !important;
    color: ${PRIMARY_COLOR};

  }
  h4, .h4 {
    font-size: ${fontSizes.h4.default}px;
    font-weight: ${fontWeights.bold};
    margin: 0;
    color: ${PRIMARY_COLOR};

  }

  a {
    user-select: none;
    text-decoration: none;
    white-space: wrap;
    text-overflow: ellipsis;
    color: ${colors.tobeornottobe};


    &:hover {
      filter: brightness(1);
    }
  }

  li, ui {
    font-size: ${fontSizes.p.default}px;
    font-weight: ${fontWeights.normal};
    color: ${colors.tobeornottobe};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    body {
    font-size: ${fontSizes.p.mobile}px;
    }
    p {
      font-size: ${fontSizes.p.mobile}px;

    }
  
    h1, .h1 {
      font-size: ${fontSizes.h1.mobile}px;
    }
    h2, .h2 {
      font-size: ${fontSizes.h2.mobile}px;
      margin: 10px 0;
    }
    h3, .h3 {
      font-size: ${fontSizes.h3.mobile}px;
      margin: 10px 0;
    }
    h4, .h4 {
      font-size: ${fontSizes.h4.mobile}px;
      margin: 0;
    }
  }

`;

export const Main = styled.main<{}>``;

export const materialTheme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
    fontSize: 13,
  },
  palette: {
    primary: {
      main: colors.flightBlue,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
          border: 0;
        }
      `,
    },
  },
});
