import styled from "styled-components";
import { CSSProperties } from "react";

import { colors, colorsRGB, fontSizes, fontWeights, PRIMARY_COLOR } from "./constants";
import { FontWeights, Color } from "./types";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";

export const Overlay = styled.div<{ zIndex?: number; }>`
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: ${props => props.zIndex ?? 3};
	left: 0;
	top: 0;
	background-color: ${colorsRGB.black(0.2)};
	overflow: hidden;
	transition: 0.5s;
`;

export const P = styled.p<{
	color?: Color;
	fontSize?: number;
	noMargin?: boolean;
	marginTop?: number;
	marginBottom?: number;
	width?: number;
	lineHeight?: number;
	underlined?: boolean;
	fontWeight?: FontWeights;
	opacity?: number;
	isMobileStyling?: boolean;
	display?: CSSProperties["display"];
	whiteSpace?: CSSProperties["whiteSpace"];
	cursor?: CSSProperties["cursor"];
	padding?: string;
	textAlign?: string;
	letterSpacing?: string;
	margin?: string;
	userSelect?: string;
}>`
	text-decoration-line: ${props => (props.underlined ? "underline" : "none")};
	background-color: transparent;
	font-weight: ${props => (props.fontWeight ? fontWeights[props.fontWeight] : fontWeights.normal)};
	${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
	${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ userSelect }) => userSelect && `user-select: ${userSelect};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px;`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ noMargin }) => noMargin && `margin: 0px;`}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ display }) => display && `display: ${display};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
		font-size: ${fontSizes.p.mobile}px;
		line-height: ${fontSizes.p.mobile * 1.2}px;
	}

	${({ isMobileStyling }) =>
		isMobileStyling &&
		`@media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
    text-align: center;
    margin: auto;
  }`}
`;

export const A = styled.a<{
	color?: Color;
	underlined?: boolean;
	bold?: boolean;
	hoverColor?: Color;
	opacity?: number;
	margin?: string;
}>`
	color: ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
	${({ underlined }) => (underlined ? "text-decoration: underline;" : "text-decoration: none;")}
	${({ margin }) => margin && `margin: ${margin};`}
  ${({ bold }) => bold && "font-weight: bold;"}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ hoverColor }) =>
		hoverColor &&
		`&:hover {
    color: ${colors[hoverColor]};
  }`}
`;

export const VerticalSeparator = styled.div<{
	color?: Color;
	width?: string;
	height?: string;
	opacity?: number;
	shouldShowMobile?: boolean;
}>`
	border-left: 1.5px solid ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
	height: ${props => (props.height ? props.height : `auto`)};
	width: ${props => (props.width ? props.width : `20px`)};
	${({ opacity }) => opacity && `opacity: ${opacity};`}
`;

export const Separator = styled.div<{
	color?: Color;
	height?: string;
	opacity?: number;
	thickness?: number;
	shouldHideDesktop?: boolean;
	marginBottom?: number;
	width?: string;
}>`
	${({ marginBottom }) =>
		marginBottom ? `margin-bottom: ${marginBottom}px;` : `margin-bottom: 10px`};
	border-bottom: ${props => props.thickness ?? 1}px solid
		${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
	${({ width }) => width && `width: ${width ?? "100%"};`}
	height: ${props => props.height ?? `20px`};
	${({ opacity }) => opacity && `opacity: ${opacity};`}

	${({ width }) => width && `width: ${width};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
		${({ shouldHideDesktop }) => shouldHideDesktop && `display:block`}
	}
	@media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
		${({ shouldHideDesktop }) => shouldHideDesktop && `display:none`}
	}
`;

export const Container = styled.div<{
	backgroundColor?: Color;
	backgroundRGBColor?: string;
	flexDirection?: CSSProperties["flexDirection"];
	flex?: CSSProperties["flex"];
	flexGrow?: CSSProperties["flexGrow"];
	flexWrap?: CSSProperties["flexWrap"];
	alignItems?: CSSProperties["alignItems"];
	alignSelf?: CSSProperties["alignSelf"];
	mobileAlignSelf?: CSSProperties["alignSelf"];
	borderRadius?: number;
	overflow?: CSSProperties["overflow"];
	paddingVertical?: number;
	paddingHorizontal?: number;
	img?: string;
	isMobileColumn?: boolean;
	mobilePadding?: string;
	tabletWidth?: string;
	mobileWidth?: string;
	justifyContent?: CSSProperties["justifyContent"];
	mobileJustifyContent?: CSSProperties["justifyContent"];
	width?: string;
	height?: string;
	mobileHeight?: string;
	opacity?: number;
	margin?: string;
	mobileMargin?: string;
	padding?: string;
	cursor?: CSSProperties["cursor"];
	hideOnMobile?: boolean;
	hideOnTablet?: boolean;
	showOnTablet?: boolean;
	zIndex?: number;
	display?: CSSProperties["display"];
	backgroundSize?: CSSProperties["backgroundSize"];
	position?: string;
	fontWeight?: number;
}>`
	display: ${props => props.display ?? "flex"};
	flex-direction: ${props => props.flexDirection ?? "initial"};
	justify-content: ${props => props.justifyContent ?? "initial"};
	align-items: ${props => props.alignItems ?? "initial"};
	align-self: ${props => props.alignSelf ?? "initial"};
	overflow: ${props => props.overflow ?? "initial"};
	border-radius: ${props => props.borderRadius ?? 0}px;

	${({ backgroundSize }) => backgroundSize && `background-size: ${backgroundSize};`}
	${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ img }) => img && `background-image: url(${img});`}
  ${({ backgroundColor }) => backgroundColor && `background-color: ${colors[backgroundColor]};`}
  ${({ backgroundRGBColor }) => backgroundRGBColor && `background-color: ${backgroundRGBColor};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ showOnTablet }) => showOnTablet && `display: none;`}
	${({ position }) => position && `position: ${position};`}
	${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
		${({ isMobileColumn }) =>
		isMobileColumn &&
		`
      flex-direction: column;
      height: auto;
      align-items: center;
      width: 100%;
    `}

		${({ mobilePadding }) => mobilePadding && `padding: ${mobilePadding}`}
      ${({ hideOnTablet }) => hideOnTablet && `display: none;`}
      ${({ showOnTablet }) => showOnTablet && `display: flex !important;`}
      ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`};
	}

	@media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
		${({ hideOnMobile }) => hideOnMobile && `display: none;`}
		${({ mobileAlignSelf }) => mobileAlignSelf && `align-self: ${mobileAlignSelf}`}
		${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`};
		${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`};
		${({ mobileHeight }) => mobileHeight && `height: ${mobileHeight};`};
	}
`;

export const GridContainer = styled.div<{
	gridTemplateColumns?: string;
	width?: string;
	margin?: string;
	repeat?: number;
	mobileRepeat?: number;
	tabletRepeat?: number;
	columnGap?: number;
	rowGap?: number;
	mobileColumnGap?: number;
	mobileRowGap?: number;
	rtl?: boolean;
	mobileWidth?: string;
}>`
	display: grid;
	grid-template-columns: repeat(${props => props.repeat ?? 4}, 1fr);
	${({ columnGap }) => columnGap && `grid-column-gap: ${columnGap}px;`}
	${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap}px;`}
  ${({ width }) => width && `width: ${width};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ rtl }) => rtl && `direction: rtl;`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
		grid-template-columns: repeat(${props => props.tabletRepeat ?? 2}, 1fr);
	}
	@media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
		grid-template-columns: repeat(${props => props.mobileRepeat ?? 1}, 1fr);
		${({ mobileColumnGap }) => mobileColumnGap && `grid-column-gap: ${mobileColumnGap}px;`}
		${({ mobileRowGap }) => mobileRowGap && `grid-row-gap: ${mobileRowGap}px;`}
		${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`}
	}
`;

export const TextButton = styled(P) <{
	color?: Color;
	hoverColor?: Color;
	underlined?: boolean;
	lineHeight?: number;
	margin?: string;
	bold?: string;
	opacity?: number;
	fontSize?: number;
}>`
	${({ margin }) => margin && `margin: ${margin};`}
	line-height: ${props => props.lineHeight ?? 18}px;
	font-size: ${props => props.fontSize ?? fontSizes.p.default}px;
	text-decoration-line: ${props => (props.underlined ? "underline" : "none")};
	color: ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
	cursor: pointer;
	margin: ${props => props.margin ?? 0}px;
	user-select: none;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: ${props => (props.bold ? fontWeights.bold : fontWeights.normal)};
	opacity: ${props => props.opacity ?? "1"};

	&:focus {
		outline: 0;
	}
	&:hover {
		color: ${props => (props.hoverColor ? colors[props.hoverColor] : colors.flightBlue)};
		opacity: 0.8;
	}
`;

export const GiveMeSomeSpace = styled.div<{ space?: number; }>`
	padding: ${props => props.space ?? 10}px;
`;

export const AbsoluteWrapper = styled.div<{
	left?: string;
	right?: string;
	top?: string;
	bottom?: string;
}>`
	position: absolute;
	${({ left }) => left && `left: ${left};`}
	${({ right }) => right && `right: ${right};`}
  ${({ top }) => top && `top: ${top};`}
  ${({ bottom }) => bottom && `bottom: ${bottom};`}
`;

export const FullHeightContainer = styled(Container) <{
	height?: string | undefined;
	width?: string | undefined;
}>`
	flex-direction: column;
	position: relative;
	width: ${props => props.width ?? "100%"};
	height: ${props => props.height ?? "100vh"};
	overflow: hidden;
`;

export const MobileContainer = styled(Container)`
	display: none;
	@media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
		display: flex;
	}
`;

export const DesktopContainer = styled(Container)`
	@media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
		display: none;
	}
`;
