import React from "react";
import styled from "styled-components";

import { Container } from "@util/standard";
import { colors, fontSizes, fontWeights, MOBILE_BREAKPOINT } from "@util/constants";
import { BlocksContent } from "@global";
import { Maybe, SanityNoteComponent } from "@graphql-types";

const Note = styled(Container)<{ bgColor?: Maybe<string> | undefined }>`
  width: 100%;
  height: 100%;
  min-height: 300px;
  padding: 80px 0 0 0;
  display: flex;
  margin: 0 22px;
  background-color: ${props => props.bgColor ?? colors.lightGrey};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0px auto 10px auto;
  }
`;

const VerticalLine = styled.div`
  border-bottom: 2px solid ${colors.flightBlue};
  height: 1px;
  width: 100%;
  margin: 12px 0 5px 0;
`;

const TextContent = styled(Container)`
  color: ${colors.flightBlue};
  font-size: ${fontSizes.h3.default}px;
  font-weight: ${fontWeights.bold};
  flex-direction: column;
  width: 100%;
  padding: 40px 40px;
  height: 100%;
  justify-content: end;
`;

const FlexContainer = styled(Container)`
  display: flex;
  height: 100%;
  width: 100%;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

interface Props {
  data: SanityNoteComponent;
}

export default function NoteComponent({ data }: Props) {
  if (data == null) return null;

  return (
    <Container
      width="85%"
      margin="100px auto"
      height="100%"
      mobileMargin="25px auto"
      mobileWidth="90%"
    >
      <FlexContainer>
        {data.notes &&
          data.notes.map(note => {
            if (note == null) return null;
            return (
              <Note bgColor={note.backgroundColor?.color} key={note._key}>
                <TextContent>
                  <Container>{note.noteTitle}</Container>
                  <VerticalLine />
                  <BlocksContent data={note.noteContent} />
                </TextContent>
              </Note>
            );
          })}
      </FlexContainer>
    </Container>
  );
}
