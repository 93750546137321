import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const HeaderQuery = () => {
  const { sanityHeader }: Query = useStaticQuery(graphql`
    {
      sanityHeader {
        siteTitle
        components {
          ... on SanityMenu {
            _key
            _type
            menuTitle
            menuItems {
              links {
                ...sanityLink
              }
              linkTitle {
                ...sanityLink
              }
            }
          }
          ... on SanityLink {
            ...sanityLink
          }
          ... on SanityCategory {
            ...sanityCategory
          }
        }
      }
    }
  `);

  return sanityHeader;
};

export default HeaderQuery;
