import React, { ReactNode } from "react";
import { Link as GatsbyLink } from "gatsby";
import styled from "styled-components";

import { A, P } from "@util/standard";
import { Color } from "@util/types";
import { Maybe, Scalars } from "@graphql-types";
import { fontWeights, MOBILE_BREAKPOINT } from "@util/constants";

interface Props {
  children?: ReactNode;
  url?: string | undefined | null;
  linkText?: Maybe<Scalars["String"]>;
  externalLink?: Maybe<Scalars["String"]>;
  internalLink?: Maybe<any>;
  className?: string;
  color?: Color;
  hoverColor?: Color;
  padding?: string;
  opacity?: number;
  margin?: string;
  fontSize?: number;
  mobileFontSize?: number;
  onClick?: () => void;
  bold?: string;
}

const StyledGatsbyLink = styled(GatsbyLink) <{
  padding?: string;
  opacity?: number;
  margin: string | undefined;
  color?: Color;
  fontSize?: number;
  bold?: string;
  mobileFontSize?: number;
}>`
  user-select: none;
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ color }) => color && `color: ${color};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  font-weight: ${props => (props.bold ? fontWeights.bold : fontWeights.normal)};
  &:hover {
    opacity: 0.8;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileFontSize }) => mobileFontSize && `font-size: ${mobileFontSize}px`};
  }
`;

const StyledP = styled(P) <{
  padding?: string;
  opacity?: number;
  margin?: string | undefined;
  color?: Color;
  fontSize?: number;
  bold?: boolean;
  mobileFontSize?: number;
}>`
  user-select: none;
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ color }) => color && `color: ${color};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  font-weight: ${props => (props.bold ? fontWeights.bold : fontWeights.normal)};
  margin: ${props => props.margin ?? "0px"};
  &:hover {
    opacity: 0.8;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileFontSize }) => mobileFontSize && `font-size: ${mobileFontSize}px`};
  }
`;

const Link = ({
  linkText,
  url,
  internalLink,
  externalLink,
  className = "",
  color,
  fontSize,
  mobileFontSize,
  hoverColor,
  padding,
  opacity,
  margin,
  onClick,
  bold,
  children,
}: Props) => {
  if (internalLink?.slug) {
    const { slug } = internalLink;
    const to = slug?.current === "/" ? "/" : `/${slug?.current}`;
    return (
      <StyledGatsbyLink
        activeClassName="active"
        className={className}
        to={to}
        padding={padding}
        opacity={opacity}
        margin={margin}
        onClick={onClick}
        color={color}
        fontSize={fontSize}
        mobileFontSize={mobileFontSize}
        bold={bold}
      >
        {children ?? linkText}
      </StyledGatsbyLink>
    );
  }

  if (externalLink) {
    return (
      <A
        href={externalLink ?? "/"}
        className={className}
        target="_blank"
        rel="noreferrer"
        color={color}
        opacity={opacity}
        hoverColor={hoverColor}
        margin={margin}
      >
        {children ?? linkText}
      </A>
    );
  }

  if (url) {
    return (
      <A
        href={url ?? "/"}
        className={className}
        color={color}
        opacity={opacity}
        hoverColor={hoverColor}
        margin={margin}
      >
        {children ?? linkText}
      </A>
    );
  }

  return (
    <StyledP
      padding={padding}
      opacity={opacity}
      margin={margin}
      onClick={onClick}
      color={color}
      fontSize={fontSize}
      bold={bold}
      mobileFontSize={mobileFontSize}
    >
      {children ?? linkText}
    </StyledP>
  );
};

export default Link;
