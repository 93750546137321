import React from "react";
import styled from "styled-components";

import { Container, P } from "@util/standard";
import { colors } from "@util/constants";
import { useFooterQuery } from "@api";
import { BlocksContent } from "@global";

const Wrapper = styled(Container)`
  width: 100%;
  padding: 5px 0;
  background-color: ${colors.flightBlue};
  p,
  a {
    color: #fff;
  }
`;

const Footer = () => {
  const data = useFooterQuery();
  if (data == null) return null;
  return (
    <Wrapper>
      <Container margin="0 0 0 60px" justifyContent="space-between" width="90%">
        <BlocksContent data={data?.footerContent} />
        <P>Made with love by (car) + trailer</P>
      </Container>
    </Wrapper>
  );
};

export default Footer;
