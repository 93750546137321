import React, { Dispatch, useEffect, useState } from "react";
import styled from "styled-components";

import { Container, GridContainer, MobileContainer, P, TextButton } from "@util/standard";
import { isSanityBlockContent } from "@util/types";
import { useAllBlogInnerQuery } from "@api";
import { assets, colors, colorsRGB, fontSizes, MOBILE_BREAKPOINT } from "@util/constants";
import { SanityBlogInner } from "@graphql-types";
import { BlogInnerPreviewContent } from "@global";
import { FormControl, OutlinedInput, InputAdornment } from "@mui/material";
import { useCheckScreenWidth } from "@util/hooks";
import { Link } from "@global";

const Overlay = styled(Container)`
  background-color: ${colors.lightOrange};
  display: block;
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0%;
  width: 100%;
  z-index: 4;
`;

interface SearchProps {
  searchParam?: string;
  setSearchParam: Dispatch<React.SetStateAction<string>>;
  setSearchOverlay: Dispatch<React.SetStateAction<boolean>>;
}

const SearchBar = ({ setSearchParam, setSearchOverlay, searchParam }: SearchProps) => {
  return (
    <Container width="100%" justifyContent="flex-end" zIndex={5}>
      <FormControl
        sx={{ m: 1, width: "248px", backgroundColor: colorsRGB.searchGrey(0.1) }}
        size="small"
        variant="outlined"
      >
        <OutlinedInput
          id="outlined-adornment-password"
          type="text"
          value={searchParam}
          sx={{ color: colors.flightBlue }}
          onChange={event => {
            setSearchParam(event.target.value);
            setSearchOverlay(true);
          }}
          endAdornment={
            <InputAdornment position="end">
              <img src={assets.search} width="17px" height="17px" />
            </InputAdornment>
          }
          label="Search"
          placeholder="Search"
        />
      </FormControl>
    </Container>
  );
};

interface Props {}

const Search = ({}: Props) => {
  const blogData = useAllBlogInnerQuery();
  const [searchResult, setSearchResult] = useState<SanityBlogInner[]>();
  const { isMobileWidth } = useCheckScreenWidth();
  const [searchParam, setSearchParam] = useState("");
  const [searchOverlay, setSearchOverlay] = useState(false);

  const searchArray = () => {
    if (searchParam.length > 0) {
      const result = blogData.filter(value => {
        const categoryResult =
          value.category &&
          value.category.filter(type => {
            return (
              type &&
              type.category?.categoryTitle?.toLowerCase().includes(searchParam.toLowerCase())
            );
          });

        const contentResult =
          value.components &&
          value.components.filter(content => {
            if (!isSanityBlockContent(content)) return;
            const contentSearch =
              content.blockContent &&
              content.blockContent.filter(block => {
                const blockResult =
                  block &&
                  block.children &&
                  block.children.filter(text => {
                    const textResult =
                      text && text.text?.toLowerCase().includes(searchParam.toLowerCase());
                    return textResult;
                  });
                return block && block.children && blockResult && blockResult.length > 0;
              });
            return contentSearch && contentSearch.length > 0;
          });
        return (
          value &&
          (value.title
            ?.toLowerCase()
            .replace(/\s/g, "")
            .includes(searchParam.toLowerCase().replace(/\s/g, "")) ||
            (categoryResult && categoryResult.length > 0) ||
            (contentResult && contentResult.length > 0))
        );
      });
      setSearchResult(result);
    }
    if (searchParam.length == 0) {
      setSearchOverlay(false);
    }
  };

  useEffect(() => {
    searchArray();
  }, [searchParam]);
  return (
    <Container width="40%" mobileWidth="10%" tabletWidth="25%">
      {!isMobileWidth && (
        <SearchBar
          setSearchParam={setSearchParam}
          setSearchOverlay={setSearchOverlay}
          searchParam={searchParam}
        />
      )}
      <MobileContainer onClick={() => setSearchOverlay(true)}>
        <img src={assets.searchIcon} />
      </MobileContainer>
      {searchOverlay && (
        <Overlay>
          <MobileContainer margin="15px 12px">
            <SearchBar setSearchParam={setSearchParam} setSearchOverlay={setSearchOverlay} />
          </MobileContainer>
          <Container
            margin="100px 20px 20px 20px"
            justifyContent="flex-end"
            mobileMargin="20px 20px"
            onClick={() => {
              setSearchOverlay(false);
              setSearchParam("");
            }}
          >
            <TextButton
              color="flightBlue"
              fontSize={fontSizes.p.default}
              bold="true"
              hoverColor="flightBlue"
            >
              Close Search
            </TextButton>
          </Container>
          {searchResult &&
          searchResult.length > 0 &&
          searchParam != " " &&
          searchParam.trim() != "" ? (
            <GridContainer
              columnGap={15}
              rowGap={15}
              width="90%"
              margin="auto"
              repeat={3}
              mobileWidth="100%"
            >
              {searchResult &&
                searchResult.map((result, index) => {
                  if (result == null) return null;
                  return (
                    <Container key={result._id} margin="auto">
                      <Link internalLink={result} onClick={() => setSearchOverlay(false)}>
                        <BlogInnerPreviewContent
                          media={result.previewMedia}
                          title={result.title}
                          category={result.category}
                          key={index}
                        />
                      </Link>
                    </Container>
                  );
                })}
            </GridContainer>
          ) : searchParam == "" ? null : (
            <Container justifyContent="center">
              <P>Sorry, No search result found</P>
            </Container>
          )}
        </Overlay>
      )}
    </Container>
  );
};

export default Search;
