import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { Container, Overlay, TextButton } from "@util/standard";
import { isSanityHexColors, isSanityImageWithMeta } from "@util/types";
import { Image } from "@global";
import { Maybe, SanityBlogInner, SanityHexColorsOrImageWithMeta } from "@graphql-types";
import {
  colors,
  colorsRGB,
  fontSizes,
  fontWeights,
  IMAC_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "@util/constants";

const Wrapper = styled(Container)`
  height: 57vh;
  width: 100%;
  margin: 150px auto auto auto;
  @media only screen and (max-width: ${IMAC_BREAKPOINT}px) {
    height: 100%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    margin: 50px auto auto auto;
  }
`;

const ButtonContainer = styled(Container)`
  height: 100%;
  width: 50%;
  position: relative;
  cursor: pointer;
  min-height: 450px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    min-height: 330px;
  }
`;

const Content = styled(Container)<{ flightBlue?: boolean }>`
  font-size: ${fontSizes.h3.default}px;
  font-weight: ${fontWeights.bold};
  color: ${props => (props.flightBlue ? colors.flightBlue : colors.white)};
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:hover {
    background-color: ${colorsRGB.black(0.2)};
  }
`;

const HexColor = styled(Container)`
  height: 100%;
  width: 100%;
`;

const StyledLink = styled(Link)`
  &:hover {
    filter: none;
  }
`;

interface Props {
  previousBlog: SanityBlogInner[];
  nextBlog: SanityBlogInner[];
}

interface Media {
  media: Maybe<Maybe<SanityHexColorsOrImageWithMeta>[]> | undefined;
  buttonTitle: string;
  title: Maybe<string> | undefined;
}

const BackOrNextButton = ({ previousBlog, nextBlog }: Props) => {
  if (previousBlog == null || nextBlog == null) {
    return null;
  }
  const BackLetter = "< Back";
  const NextLetter = "Next >";

  const BackgroundMedia = ({ media, buttonTitle, title }: Media) => {
    if (media == null) return null;
    return (
      <>
        {media.map(type => {
          if (type == null) return null;
          if (isSanityImageWithMeta(type)) {
            return (
              <React.Fragment key={type._key}>
                <Overlay />
                <Image data={type} objectFit="cover" isBackground />
                <Content>
                  {title}
                  <TextButton
                    color="white"
                    fontSize={fontSizes.h3.default}
                    bold="true"
                    margin="40px 0 0 0"
                    hoverColor="black"
                  >
                    {buttonTitle}
                  </TextButton>
                </Content>
              </React.Fragment>
            );
          }
          if (isSanityHexColors(type)) {
            if (type == null || type.color == null) return null;
            return (
              <HexColor backgroundRGBColor={type.color} key={type.id}>
                <Overlay />
                <Content flightBlue>
                  {title}
                  <TextButton
                    color="flightBlue"
                    fontSize={fontSizes.h3.default}
                    bold="true"
                    margin="40px 0 0 0"
                    hoverColor="black"
                  >
                    {buttonTitle}
                  </TextButton>
                </Content>
              </HexColor>
            );
          }
          return null;
        })}
      </>
    );
  };
  return (
    <Wrapper>
      <ButtonContainer>
        <StyledLink to={`/${previousBlog[0].slug?.current}`}>
          <BackgroundMedia
            media={previousBlog[0].previewMedia}
            buttonTitle={BackLetter}
            title={previousBlog[0].title}
          />
        </StyledLink>
      </ButtonContainer>
      <ButtonContainer>
        <StyledLink to={`/${nextBlog[0].slug?.current}`}>
          <BackgroundMedia
            media={nextBlog[0].previewMedia}
            buttonTitle={NextLetter}
            title={nextBlog[0].title}
          />
        </StyledLink>
      </ButtonContainer>
    </Wrapper>
  );
};

export default BackOrNextButton;
