import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { Container, P } from "@util/standard";
import { IconButton, MenuDrawer, Search } from "@global";
import { assets, colors, fontSizes, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { useHeaderQuery } from "@api";
import Menu from "./menu";
import { useCheckScreenWidth } from "@util/hooks";

const HeaderStyled = styled.header<{ hasScrolled: boolean }>`
  position: fixed;
  z-index: 20;
  top: 0;
  width: 100%;
  background-color: ${props => (props.hasScrolled ? `${colors.white}` : `${colors.transparent}`)};

  ${({ hasScrolled }) =>
    hasScrolled &&
    `p,a { 
    color: ${colors.flightBlue} !important;
  }`}
`;

const Wrapper = styled(Container)`
  width: 90%;
  margin: 0 auto;
  padding: 15px 0;
  background-color: ${colors.transparent};
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 95%;
  }
`;

const Title = styled(P)`
  font-size: ${fontSizes.h4.default}px;
`;

const TitleContainer = styled(Container)`
  width: 20%;
  justify-content: flex-start;
  z-index: 5;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 35%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 60%;
    z-index: 1;
  }
`;

const StyledLinks = styled(Container)`
  display: flex;
  column-gap: 25px;
  font-weight: 700;
  font-size: 17px;
  a {
    color: #fff;
  }
`;

const Header = () => {
  const header = useHeaderQuery();
  if (header == null) return null;

  const { siteTitle } = header;
  const { isMobileWidth, isTabletWidth } = useCheckScreenWidth();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [menuOpacity, setMenuOpacity] = useState(true);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [fullOpen, setFullOpen] = useState(false);
  const [nestedMenuIndex, setNestedMenuIndex] = useState(-1);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  const handClick = () => {
    setDrawerVisible(false);
    setAccordionOpen(false);
    setMenuOpacity(true);
    setNestedMenuIndex(-1);
    setFullOpen(false);
    setMobileMenu(false);
  };

  const handleScroll = () => {
    const scrollDistance = document.body.scrollTop + document.documentElement.scrollTop;

    if (scrollDistance > 10) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleScroll();
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <HeaderStyled hasScrolled={hasScrolled}>
      <Wrapper>
        <TitleContainer>
          {hasScrolled ? (
            <img src={assets.logoBlue} style={{ width: "110px" }} />
          ) : (
            <img src={assets.logoSVG} style={{ width: "110px" }} />
          )}
          <P margin="auto 0 auto 15px" fontSize={25} color="white">
            <a href="/" style={{ color: "#fff" }}>
              Manual
            </a>
          </P>
        </TitleContainer>
        <StyledLinks>
          <a href="/being-here">Being here</a>
          <a href="/our-manifesto">Our manifesto</a>
          <a href="/grow-with-us">Grow with us</a>
          <a href="/the-fine-print">The fine print</a>
        </StyledLinks>
      </Wrapper>
    </HeaderStyled>
  );
};

export default Header;
