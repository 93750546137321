import React, { SyntheticEvent, useState } from "react";
import styled from "styled-components";

import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { SanityMenu } from "@graphql-types";
import { Link } from "@global";
import { Container } from "@util/standard";
import { assets, colors, fontSizes } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";

const Icon = styled(props => (
  <div {...props}>
    <Container className="expand">
      <img src={assets.closeMenu} width="15px" height="15px" />
    </Container>
    <Container className="collapse">
      <img src={assets.plusLogo} width="15px" height="15px" />
    </Container>
  </div>
))`
  & > .collapse {
    display: block;
  }
  & > .expand {
    display: none;
  }
  .Mui-expanded & > .expand {
    display: block;
  }
  .Mui-expanded & > .collapse {
    display: none;
  }
`;

const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    background-color: ${colors.flightBlue};
    box-shadow: none;
    border-bottom: 1px solid white;
  }
  &.MuiAccordion-root:last-of-type {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

const Wrapper = styled(Container)`
  position: relative;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

interface Props {
  data: SanityMenu;
}

export default function AccordionsBlock({ data }: Props) {
  const { menuItems } = data;
  const [expanded, setExpanded] = useState<string | false>(false);
  const { isMobileWidth } = useCheckScreenWidth();

  const handleChange =
    (panel: string, menuLink: number) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded && menuLink > 0 ? panel : false);
    };

  return (
    <Wrapper>
      {menuItems &&
        menuItems.map((menu, index) => {
          if (menu == null) return null;
          return (
            <div key={index}>
              <StyledAccordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`, menu.links?.length as number)}
              >
                <AccordionSummary
                  expandIcon={(menu.links?.length as number) > 0 && <Icon />}
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                  sx={{ padding: "20px 0px" }}
                >
                  <Typography sx={{ width: "100%", flexShrink: 0 }}>
                    <Link
                      {...menu.linkTitle}
                      color="white"
                      fontSize={fontSizes.h4.default}
                      mobileFontSize={fontSizes.h4.mobile}
                    />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: "0px 15px 40px",
                  }}
                >
                  <Typography>
                    {menu.links &&
                      menu.links.map(nestedMenu => {
                        if (nestedMenu == null) return null;
                        return (
                          <React.Fragment key={nestedMenu._key}>
                            <Link
                              {...nestedMenu}
                              color="white"
                              fontSize={fontSizes.h4.default}
                              mobileFontSize={fontSizes.h4.mobile}
                            />
                          </React.Fragment>
                        );
                      })}
                  </Typography>
                </AccordionDetails>
              </StyledAccordion>
            </div>
          );
        })}
    </Wrapper>
  );
}
