import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const HomeQuery = () => {
  const { sanityPage }: Query = useStaticQuery(graphql`
    {
      sanityPage {
        title
        hero {
          _key
          _rawBlockContent
          blockContent {
            _rawBlockContent
          }
        }
      }
    }
  `);

  return sanityPage;
};

export default HomeQuery;
