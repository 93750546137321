export const BUTTON_HEIGHT = 46;
export const HEADER_HEIGHT = 84;

// screen sizes
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;
export const DEFAULT_OFFSET = 120;
export const MOBILE_OFFSET = 60;

export const colors = {
  transparent: "transparent",
  white: "#fff",
  black: "#000",
  flightBlue: "#24468E",
  errorRed: "#ff0000",
  lightOrange: "#FAD6B2",
  lightBlue: "#BACDD1",
  lightGrey: "#D3D3D3",
  lightPurple: "#D1BDD4",
  tobeornottobe: "#2b2b2b",
};

export const fontWeights = {
  normal: 400,
  extraNormal: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
};

export const PRIMARY_COLOR = colors.flightBlue;
export const SECONDARY_COLOR = colors.black;

export const fontSizes = {
  p: { default: 17, mobile: 12 },
  h1: { default: 45, mobile: 50 },
  h2: { default: 42, mobile: 40 },
  h3: { default: 24, mobile: 20 },
  h4: { default: 20, mobile: 14 },
  menu: { default: 50, mobile: 40 },
  span: { default: 16, mobile: 12 },
};

export const colorsRGB = {
  flightBlue: (opacity?: number) => `rgba(0, 28, 168, ${opacity ?? "0.4"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
  searchGrey: (opacity?: number) => `rgba(36, 70, 142, ${opacity ?? "0.1"})`,
};

export const pages = {
  home: "/",
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  search: "/search",
  notFound: "/404",
};

// define paths to asset
export const assets = {
  logo: require("@static/assets/logo.png").default,
  hamburger: require("@static/assets/hamburger.svg").default,
  close: require("@static/assets/close-white.svg").default,
  search: require("@static/assets/search.svg").default,
  menuDot: require("@static/assets/dot.svg").default,
  plusLogo: require("@static/assets/plusLogo.png").default,
  dotBlue: require("@static/assets/dot-blue.svg").default,
  mouseLogo: require("@static/assets/mouseLogo.svg").default,
  flightLogo: require("@static/assets/flightLogo.svg").default,
  backButtonImage: require("@static/assets/backButtonImage.png").default,
  closeMenu: require("@static/assets/close-menu.svg").default,
  searchIcon: require("@static/assets/searchIcon.svg").default,
  closeBlack: require("@static/assets/close.svg").default,
  logoSVG: require("@static/assets/icon_white.svg").default,
  logoBlue: require("@static/assets/icon_blue.svg").default,
};

export const buttonStyle = {
  base: {
    bg: `${colors.transparent}`,
    text: `${colors.flightBlue}`,
    border: `${colors.flightBlue}`,
    hoverBg: `${colors.flightBlue}`,
    hoverBorder: `${colors.flightBlue}`,
    hoverText: `${colors.white}`,
  },
  white: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.flightBlue}`,
    hoverBorder: `${colors.flightBlue}`,
    hoverText: `${colors.white}`,
  },
  whiteInverse: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.flightBlue}`,
    hoverText: `${colors.flightBlue}`,
  },
};
