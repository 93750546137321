import React, { Dispatch, useState } from "react";
import styled from "styled-components";

import { Container, TextButton } from "@util/standard";
import { colors, fontSizes, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { Maybe, SanityCategoryOrLinkOrMenu } from "@graphql-types";
import { AccordionsBlock, BlogInnerPreview } from "@global";
import { isSanityCategory, isSanityMenu } from "@util/types";
import { useAllBlogInnerQuery } from "@api";
import { useCheckScreenWidth } from "@util/hooks";

const Wrapper = styled(Container)<{ isCategory?: boolean }>`
  position: relative;
  width: 100%;
  height: 83vh;
  margin: 0 0 0 20px;
  overflow: hidden;
  justify-content: center;
  background-color: ${colors.flightBlue};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 100%;
    margin: ${props => (props.isCategory ? "0" : "20px 0 0 40px")};
  }
`;

interface Props {
  menuData: Maybe<SanityCategoryOrLinkOrMenu>[];
  nestedMenuIndex: number;
  setFullOpen: Dispatch<React.SetStateAction<boolean>>;
  setMobileMenu: Dispatch<React.SetStateAction<boolean>>;
}

const NestedMenu = ({ menuData, nestedMenuIndex, setFullOpen, setMobileMenu }: Props) => {
  if (menuData == null || nestedMenuIndex < 0) return null;
  const { isMobileWidth, isTabletWidth } = useCheckScreenWidth();
  const [isCategory, setIsCategory] = useState(false);
  const BackButton = "< Back";

  const Menu = () => {
    const data = menuData[nestedMenuIndex];
    if (data == null) return null;

    if (isSanityMenu(data)) {
      if (data == null) return null;
      return (
        <Container key={data._key} width="80%" mobileWidth="85%" overflow="hidden">
          <AccordionsBlock data={data} />
        </Container>
      );
    }

    if (isSanityCategory(data)) {
      if (data == null) return null;
      setIsCategory(true);
      const categoryType = data.slug?.current;
      const allBlogInner = useAllBlogInnerQuery();

      const filterBlog =
        allBlogInner &&
        allBlogInner.filter(blog => {
          return (
            blog.category &&
            blog.category.filter(type => type && type.category?.slug?.current == categoryType)
              .length > 0
          );
        });
      if (filterBlog && filterBlog.length > 2) {
        setFullOpen(true);
      }
      return <BlogInnerPreview blogs={filterBlog} key={data._id} />;
    }
    return null;
  };

  return (
    <Wrapper isCategory={isCategory}>
      {(isMobileWidth || isTabletWidth) && (
        <Container
          margin={isCategory ? "20px 30px" : "20px 0"}
          onClick={() => setMobileMenu(false)}
        >
          <TextButton color="white" fontSize={fontSizes.p.default} bold="true" hoverColor="white">
            {BackButton}
          </TextButton>
        </Container>
      )}
      <Menu />
    </Wrapper>
  );
};

export default NestedMenu;
