import React from "react";
import styled from "styled-components";

import { Maybe, SanityHexColorsOrImageWithMeta, SanityRefCategory } from "@graphql-types";
import { Container, P } from "@util/standard";
import { isSanityHexColors, isSanityImageWithMeta } from "@util/types";
import { Image } from "@global";
import {
  assets,
  colors,
  colorsRGB,
  fontSizes,
  fontWeights,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";

const Wrapper = styled(Container)<{ isHome?: boolean }>`
  margin: ${props => (props.isHome ? "0" : "20px 20px")};
  width: 100%;
  position: relative;
  justify-content: center;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: ${props => (props.isHome ? "auto" : "10px auto")};
    width: ${props => (props.isHome ? "100%" : "95%")};
  }
`;

const VerticalLine = styled.div<{ borderColor?: string }>`
  border-bottom: 2px solid ${props => props.borderColor ?? `${colors.white}`};
  height: 1px;
  width: 100%;
  margin: 12px 0 5px 0;
`;

const HexColorContent = styled(Container)<{ isHome?: boolean }>`
  width: ${props => (props.isHome ? "100%" : "374px")};
  height: ${props => (props.isHome ? "280px" : "346px")};
  justify-content: center;
  display: flex;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: 0.3s ease-in;
    background-color: ${colorsRGB.black(0.1)};
    overflow: hidden;
    cursor: pointer;
  }
  &:hover {
    &:before {
      background-color: ${colorsRGB.black(0.5)};
    }
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: ${props => (props.isHome ? "100%" : "300px")};
    height: ${props => (props.isHome ? "280px " : "270px")};
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: ${props => (props.isHome ? "100%" : "358px")};
    height: ${props => (props.isHome ? "280px " : "170px")};
  }
`;

const Content = styled(Container)<{ isHome?: boolean; isLandscape?: boolean }>`
  width: ${props => (props.isHome ? "100%" : "374px")};
  height: ${props =>
    props.isHome && props.isLandscape ? "280px" : props.isHome ? "515px " : "346px"};
  justify-content: center;
  display: flex;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: 0.3s ease-in;
    background-color: ${colorsRGB.black(0.1)};
    overflow: hidden;
    cursor: pointer;
  }
  &:hover {
    &:before {
      background-color: ${colorsRGB.black(0.5)};
    }
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: ${props => (props.isHome ? "100%" : "300px")};
    height: ${props => (props.isHome ? "280px " : "270px")};
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: ${props => (props.isHome ? "100%" : "358px")};
    height: ${props => (props.isHome ? "515px " : "170px")};
  }
`;

const TextContent = styled(Container)<{ color?: string }>`
  color: ${props => props.color ?? `${colors.white}`};
  position: absolute;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  font-size: ${fontSizes.h3.default}px;
  font-weight: ${fontWeights.bold};
  flex-direction: column;
  width: 100%;
  padding: 40px 40px;
  height: 100%;
  z-index: 2;
`;

const MenuDot = styled.img`
  width: 4px;
  height: 4px;
  margin: auto 10px auto 0;
`;

interface TitleProps {
  hexColor?: boolean;
  type: any;
}

const DisplayTitle = ({ type, hexColor }: TitleProps) => {
  return (
    <Container flexDirection="row" margin="0 20px 0 0" alignItems="center">
      <MenuDot src={hexColor ? `${assets.dotBlue}` : `${assets.menuDot}`} />
      <P fontSize={fontSizes.p.default} color={hexColor ? "flightBlue" : "white"}>
        {type.category?.categoryTitle}
      </P>
    </Container>
  );
};

interface Media {
  media: Maybe<Maybe<SanityHexColorsOrImageWithMeta>[]> | undefined;
  title: Maybe<string> | undefined;
  category: Maybe<Maybe<SanityRefCategory>[]> | undefined;
  isHome?: boolean;
}

const BlogInnerPreviewContent = ({ media, title, category, isHome }: Media) => {
  const { isMobileWidth } = useCheckScreenWidth();

  return (
    <Wrapper isHome={isHome}>
      {media &&
        media.map((value, index) => {
          if (isSanityImageWithMeta(value)) {
            const ImageRatio =
              (value?.asset?.metadata?.dimensions?.height as number) /
              (value?.asset?.metadata?.dimensions?.width as number);
            return (
              <Content key={value._key} isHome={isHome} isLandscape={ImageRatio < 0.8}>
                <Image data={value} objectFit="cover" />
                <TextContent>
                  <Container>{title}</Container>
                  {(!isMobileWidth || isHome) && (
                    <>
                      <VerticalLine />
                      <Container>
                        {category &&
                          category.map((type, index) => {
                            if (type == null) return null;
                            return <DisplayTitle type={type} key={(type._key as string) + index} />;
                          })}
                      </Container>
                    </>
                  )}
                </TextContent>
              </Content>
            );
          }
          if (isSanityHexColors(value)) {
            if (value == null || value.color == null) return null;
            return (
              <HexColorContent
                backgroundRGBColor={value.color}
                key={value.id + index}
                isHome={isHome}
              >
                <TextContent color={colors.flightBlue}>
                  <Container>{title}</Container>
                  {(!isMobileWidth || isHome) && (
                    <>
                      <VerticalLine borderColor={colors.flightBlue} />
                      <Container>
                        {category &&
                          category.map((type, index) => {
                            if (type == null) return null;
                            return (
                              <DisplayTitle
                                type={type}
                                hexColor
                                key={(type._key as string) + index}
                              />
                            );
                          })}
                      </Container>
                    </>
                  )}
                </TextContent>
              </HexColorContent>
            );
          }
          return null;
        })}
    </Wrapper>
  );
};

export default BlogInnerPreviewContent;
