import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const FooterQuery = () => {
  const { sanityFooter }: Query = useStaticQuery(graphql`
    {
      sanityFooter {
        id
        footerContent {
          _rawBlockContent
          _key
        }
      }
    }
  `);

  return sanityFooter;
};

export default FooterQuery;
