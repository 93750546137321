import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { SanityBlogInner } from "@graphql-types";
import { Container } from "@util/standard";
import { BlogInnerPreviewContent } from "@global";

const Wrapper = styled(Container)`
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: center;
`;

interface Props {
  blogs: SanityBlogInner[];
}

const BlogInnerPreview = ({ blogs }: Props) => {
  if (blogs == null) return null;
  return (
    <Wrapper flexDirection={blogs.length > 2 ? "row" : "column"}>
      {blogs.map(blog => {
        if (blog == null) return null;
        return (
          <Container key={blog._id}>
            <Link to={`/${blog.slug?.current as string}`}>
              <BlogInnerPreviewContent
                media={blog.previewMedia}
                title={blog.title}
                category={blog.category}
              />
            </Link>
          </Container>
        );
      })}
    </Wrapper>
  );
};

export default BlogInnerPreview;
