import React, { ReactNode } from "react";

import Header from "./header";
import Footer from "./footer";
import { GlobalStyle, Main } from "@styles/globalStyles";
import { ProviderContainer } from "@global";
import { Helmet } from "react-helmet";
interface Props {
  children: ReactNode;
}

function Layout(props: Props) {
  const { children } = props;

  return (
    <div>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <GlobalStyle />
      <ProviderContainer>
        <Header />
        <Main>{children}</Main>
        <Footer />
      </ProviderContainer>
    </div>
  );
}

export default Layout;
