import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const CategoryQuery = () => {
  const { allSanityCategory }: Query = useStaticQuery(graphql`
    {
      allSanityCategory {
        edges {
          node {
            categoryTitle
            slug {
              current
            }
            _id
          }
        }
      }
    }
  `);

  const CategoryType = allSanityCategory.edges;
  return CategoryType;
};

export default CategoryQuery;
